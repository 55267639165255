var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 py-5 d-none d-md-block"},[_c('carousel'),_c('h5',{staticClass:"Copyright-2021-Dat"},[_vm._v(" Copyright © 2021 Data Poem All rights reserved. Privacy and Legal Policies ")])],1),_c('div',{staticClass:"col-md-6 py-5"},[_c('div',{staticClass:"auth-wrapper"},[_c('div',{staticClass:"auth-inner content-center"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"Forgot-your-password-"},[_vm._v(" Forgot your password? ")]),_c('div',{staticClass:"Enter-your-registere center-block"},[_vm._v(" Dont't worry! It happens to the best of us. So many passwords to remember! Help is on the way. ")]),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"w-100 d-flex my-5"},[_c('b-form-group',{staticClass:"w-100 p-2",attrs:{"id":"example-input-group-reset-password"}},[_c('div',{staticClass:"d-flex w-100 p-0 row m-0 px-md-5 px-0"},[(!_vm.otpProcess)?_c('div',{staticClass:"w-100 p-0"},[_c('span',{staticClass:"content-center inputIcon",class:{
                              invalid: _vm.$v.form.email.$error,
                              success:
                                !_vm.$v.form.email.$error &&
                                _vm.$v.form.email.$model == '',
                            }},[_c('img',{attrs:{"src":require("@/assets/user-line.svg"),"alt":"userIcon"}})]),_c('b-form-input',{staticClass:"form-control c-form-input m-0",staticStyle:{"width":"90%"},attrs:{"id":"forgotpasswordInput","name":"example-input-1","state":_vm.validateState('email'),"aria-describedby":"input-1-live-feedback","autocomplete":"off","placeholder":"Registered Email Id","type":"email"},on:{"input":function($event){[(_vm.errorMessage = false)]}},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", $$v)},expression:"$v.form.email.$model"}}),_c('b-form-invalid-feedback',{staticClass:"w-100",attrs:{"id":"input-1-live-feedback"}},[_vm._v("Invalid Email.")])],1):_c('div',{staticClass:"w-100 p-0 mt-5"},[_c('span',{staticClass:"content-center inputIcon"},[_c('img',{attrs:{"src":require("@/assets/user-line.svg"),"alt":"userIcon"}})]),_c('b-form-input',{staticClass:"form-control c-form-input m-0",staticStyle:{"width":"90%"},attrs:{"aria-describedby":"input-1-live-feedback","autocomplete":"off","placeholder":"Enter OTP","type":"number"},model:{value:(_vm.validateOTP),callback:function ($$v) {_vm.validateOTP=$$v},expression:"validateOTP"}})],1),(_vm.errorMessage)?_c('span',{staticClass:"mt-3",staticStyle:{"color":"red"}},[_vm._v("This Email is not registered with us.")]):_vm._e()])])],1),(!_vm.otpProcess)?_c('div',{staticClass:"text-right mt-5 px-0 px-md-5"},[_c('w-button',{staticClass:"mr-2",class:{ resetPasswordLink: !_vm.validForm },attrs:{"buttonLoader":_vm.loader,"buttonText":_vm.buttonText,"isDisable":!_vm.validForm},on:{"buttonClicked":_vm.onSubmit}})],1):_c('div',{staticClass:"text-right mt-5 px-0 px-md-5"},[_c('w-button',{staticClass:"mr-2",class:{ resetPasswordLink: _vm.validateOTP.length !== 6 },attrs:{"buttonLoader":_vm.submitButtonLoader,"buttonText":'Submit',"isDisable":_vm.validateOTP.length !== 6},on:{"buttonClicked":_vm.resetPassword}})],1)])],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 content-center mb-5"},[_c('img',{staticClass:"Bitmap",attrs:{"src":require("../assets/bitmap.png"),"srcset":require("../assets/bitmap@2x.png") + " 2x, " + require("../assets/bitmap@3x.png") + " 3x"}})])
}]

export { render, staticRenderFns }